// @ts-ignore
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";
import { Controller, Scene } from "react-scrollmagic";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { OverlayFillGradient } from "../../components/shared/overlay";
import SEO from "../../components/shared/seo";
import { FadeIn, FadeUpBox } from "../../components/shared/transitions";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { QuoteBlock } from "../../components/shared/quote-block";
import { Helmet } from "react-helmet";

function WesfarmersPage({ data }) {
  const [animate, setAnimate] = useState(false);

  const wesImages = useStaticQuery(graphql`
    query {
      wesBanner: file(relativePath: { eq: "wes-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      wesLogoSm: file(relativePath: { eq: "wesfarmers-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      prevWorkImage: file(relativePath: { eq: "card-energyaction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "stjohn-vas-casestudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sitefinityLogo: file(relativePath: { eq: "sitefinity-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Wesfarmers Corporate Affairs",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv have been nothing but professional, responsive, helpful and proactive since we started working with them. Highly recommend for corporate website support and service.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO title="Wesfarmers" />
      <LayoutCaseStudy>
        <div
          id="wesfarmers-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={wesImages.wesLogoSm.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Support and Development</h6>
                  </FadeIn>
                  <FadeIn>
                    <h2>Wesfarmers</h2>
                  </FadeIn>
                  <FadeIn>
                    <p>
                      Wesfarmers have been under support and maintenance with us
                      since November 2017, the process involved a move from
                      previous provider who went into administration. We helped
                      Wesfarmers with a plan to secure their crucial digital
                      assets which was important for their ongoing support.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
            <Row className="" id="cms-section">
              <Col>
                <Controller>
                  <Scene
                    triggerElement="#cms-section"
                    reverse={false}
                    duration={100}
                  >
                    {(progress, event) => (
                      <FadeUpBox
                        pose={progress > 0 ? "enter" : "exit"}
                        delay={200}
                      >
                        <FadeIn>
                          <Row>
                            <Col
                              sm={12}
                              md={12}
                              className="cms-outer-container"
                            >
                              <Col
                                sm={12}
                                md={12}
                                lg={9}
                                className="cms-inner-container"
                              >
                                <div className="logoWrapper">
                                  <div>
                                    <Image
                                      fluid
                                      src={
                                        wesImages.sitefinityLogo.childImageSharp
                                          .fluid.src
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="textWrapper">
                                  <h4>Sitefinity CMS</h4>

                                  <p>
                                    Wesfarmers website is built on Sitefinity
                                    CMS, since we had the solution under control
                                    we have been rolling out improvements and
                                    more recently, to support changes to the
                                    NASDAQ feed for publishing announcements and
                                    share pricing information to investors.
                                  </p>
                                </div>
                              </Col>
                            </Col>
                          </Row>
                        </FadeIn>
                      </FadeUpBox>
                    )}
                  </Scene>
                </Controller>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="quote-wrapper">
          <Container>
            <QuoteBlock
              author="Wesfarmers"
              authorPosition="Corporate Affairs"
              quoteText="Diviv have been nothing but professional, responsive, helpful and proactive since we started working with them. Highly recommend for corporate website support and service."
              clientLogoUrl={wesImages.wesLogoSm.childImageSharp.fluid.src}
            />
          </Container>
        </section>

        <CaseStudyNav
          nextUrl="/our-work/stjohn-ambulance/volunteer-accounting-system"
          nextImage={wesImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="St John Ambulance VAS"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default WesfarmersPage;
